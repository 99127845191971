import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Cta from "../../components/Cta";
import IconBox from "../../components/IconBox";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import TestimonialSlider from "../../components/Slider/TestimonialSlider";
import Spacing from "../../components/Spacing";

export default function WebsiteDevelopment() {
  pageTitle("Website Development");
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Website Development"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={"Website Development"}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="DEVELOPMENT WORKING PROCESS"
          subtitle="WEBSITE DEVELOPMENT"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.svg"
              title="Planning"
              subtitle="We begin with comprehensive planning to define the project scope, goals, and requirements. This ensures a clear roadmap for the development process."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.svg"
              title="Designing"
              subtitle="Next, our designers create visually appealing and user-friendly layouts that align with your brand identity. This sets the foundation for a great user experience."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.svg"
              title="Development"
              subtitle="Our developers bring the designs to life using the latest technologies and best practices. We ensure the website is responsive, fast, and secure."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              Below our most development related services
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="Front-End Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Back-End Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Full-Stack Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="CMS Integration"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="eCommerce Solutions"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Custom Web Applications"
                  variant="cs-type2"
                />
                <Spacing lg="0" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="API Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Web Maintenance"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="SEO Optimization"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Performance Optimization"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
