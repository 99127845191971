import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Cta from "../../components/Cta";
import IconBox from "../../components/IconBox";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import TestimonialSlider from "../../components/Slider/TestimonialSlider";
import Spacing from "../../components/Spacing";

export default function GraphicDesign() {
  pageTitle("Graphic Design");
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Graphic Design"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={"Graphic Design"}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Design working process"
          subtitle="GRAPHIC DESIGN"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.svg"
              title="Sketching"
              subtitle="In the initial stage, we brainstorm and create rough sketches to bring your ideas to life. This helps us visualize the concept and make necessary adjustments early on."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.svg"
              title="Wireframing"
              subtitle="Next, we develop detailed wireframes that serve as a blueprint for the final design. This step ensures that all elements are properly arranged and functional."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.svg"
              title="Prototyping"
              subtitle="Finally, we create high-fidelity prototypes that showcase the complete look and feel of your project. This allows us to test and refine the design before the final implementation."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              BELOW OUR MOST DESIGN RELATED SERVICES
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="Logo Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Brand Identity"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Print Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Packaging Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Social Media Graphics"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Infographics"
                  variant="cs-type2"
                />
                <Spacing lg="0" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="Marketing Materials"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Illustration"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Website Graphics"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Presentation Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
