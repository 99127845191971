import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Cta from "../../components/Cta";
import IconBox from "../../components/IconBox";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import TestimonialSlider from "../../components/Slider/TestimonialSlider";
import Spacing from "../../components/Spacing";

export default function UIUXDesign() {
  pageTitle("UI/UX Design");
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="UI/UX Design"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={"UI/UX Design"}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Design working process"
          subtitle="UI/UX Design"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.svg"
              title="Research"
              subtitle="We start by conducting thorough research to understand your users and their needs. This helps us design user-centric interfaces that provide a seamless experience."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.svg"
              title="Wireframing"
              subtitle="Using insights from our research, we create detailed wireframes that outline the structure and layout of the interface. This step is crucial for planning the user journey."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.svg"
              title="Prototyping"
              subtitle="We then develop interactive prototypes to test the usability and functionality of the design. This allows us to gather feedback and make improvements before the final launch."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              Below our most design related services
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="User Research"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Wireframing"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Prototyping"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Usability Testing"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="User Interface Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="User Experience Design"
                  variant="cs-type2"
                />
                <Spacing lg="0" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="Interaction Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Responsive Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Visual Design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Information Architecture"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
