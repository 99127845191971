import React, { useEffect } from "react";
import { pageTitle } from "../helper";
import Cta from "../components/Cta";
import FunFact from "../components/FunFact";
import PageHeading from "../components/PageHeading";
import Div from "../components/Div";
import SectionHeading from "../components/SectionHeading";
import Spacing from "../components/Spacing";
import Team from "../components/Team";
import SocialWidget from "../components/Widget/SocialWidget";
const teamData = [
  // {
  //   memberImage: "/images/member_1.jpeg",
  //   memberName: "Melon Bulgery",
  //   memberDesignation: "Product Designer",
  // },
  // {
  //   memberImage: "/images/member_2.jpeg",
  //   memberName: "Olinaz Fushi",
  //   memberDesignation: "Product Designer",
  // },
  // {
  //   memberImage: "/images/member_3.jpeg",
  //   memberName: "David Elone",
  //   memberDesignation: "React Developer",
  // },
];
const funfaceData = [
  {
    // title: "Projects Completed",
    // factNumber: "5",
  },
  {
    title: "Global Happy Clients",
    factNumber: "4",
  },
  {
    //   title: "Team Members",
    //   factNumber: "3",
  },
  {
    title: "Projects Completed",
    factNumber: "5",
  },
];

export default function AboutPage() {
  pageTitle("About");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your Creative Partner in Success"
              subtitle="About Dapplic"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Dapplic, we’re more than just a design agency—we're your
                creative partners in growth. We pride ourselves on offering
                personalized design solutions that truly understand and cater to
                your business needs. With our team's extensive experience and
                innovative approach, we craft strategies that help you shine in
                the competitive market. We believe every business has its own
                story, and we're here to help you tell it in the most impactful
                way.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="From crafting unique brands to delivering top-notch design projects, we're just getting started."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="EXPERIENCE YOU CAN TRUSTus"
              subtitle="WHY DAPPLIC?"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Dapplic, our greatest asset is our team of highly experienced
                professionals. This sets us apart from the competition and
                enables us to provide specialized digital consultancy services
                tailored to your unique needs. Our team brings a wealth of
                knowledge and expertise to every project, ensuring we deliver
                the best possible solutions for your business.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Over the years, we’ve learned that every business is unique. We
                use our extensive experience to develop strategies that work
                across all design aspects, helping your business thrive in
                today’s competitive landscape.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Choose Dapplic as your creative partner and trust us to turn
                your vision into reality with our dedicated and skilled team.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
{/*       <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Meet the Founders"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <Div className="row">
          <Div className="container">
            <Div className="row align-items-center">
              <Div className="col-xl-5 col-lg-6">
                <Div className="cs-radius_15 cs-shine_hover_1">
                  <img
                    src="/images/member_details_1.jpeg"
                    alt="Member"
                    className="w-100"
                  />
                </Div>
              </Div>
              <Div className="col-lg-6 offset-xl-1">
                <Spacing lg="0" md="45" />
                <Div className="cs-section_heading cs-style1">
                  <h2 className="cs-section_title">Devanshi Desai</h2>
                  <Div className="cs-height_10 cs-height_lg_10" />
                  <h3 className="cs-section_subtitle">
                    Co-Founder and Creative Director
                  </h3>
                  <Div className="cs-height_5 cs-height_lg_5" />
                  <Div className="cs-separator cs-accent_bg" />
                  <Div className="cs-height_45 cs-height_lg_25" />
                  <p className="cs-m0">
                    Devanshi Desai, a passionate and creative graphic designer
                    and motion graphic designer, co-founded Dapplic to bring
                    innovative and beautiful designs to life. With a wealth of
                    experience in freelance design, she focuses on crafting
                    unique design strategies and overseeing project execution at
                    Dapplic. Devanshi's dedication to transforming client ideas
                    into compelling visual stories ensures each project stands
                    out and makes a lasting impact.
                  </p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                  <p className="cs-m0">
                    Beyond her technical skills, Devanshi's enthusiasm for
                    design and unwavering commitment to quality drive the
                    agency’s creative vision and success. She believes in the
                    power of design to tell stories, evoke emotions, and create
                    memorable experiences. Her leadership at Dapplic is marked
                    by a blend of creativity, strategic thinking, and a genuine
                    passion for helping clients achieve their branding goals.
                  </p>
                  <Div className="cs-height_45 cs-height_lg_30" />
                   <SocialWidget /> 
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <Div className="container">
            <Div className="row align-items-center">
              <Div className="col-lg-6 offset-xl-1">
                <Spacing lg="0" md="45" />
                <Div className="cs-section_heading cs-style1">
                  <h2 className="cs-section_title">Savan Desai</h2>
                  <Div className="cs-height_10 cs-height_lg_10" />
                  <h3 className="cs-section_subtitle">
                    Co-Founder and Lead Designer
                  </h3>
                  <Div className="cs-height_5 cs-height_lg_5" />
                  <Div className="cs-separator cs-accent_bg" />
                  <Div className="cs-height_45 cs-height_lg_25" />
                  <p className="cs-m0">
                    Savan Desai, a talented and detail-oriented graphic
                    designer, co-leads Dapplic with a focus on delivering
                    impactful design solutions. His extensive experience with
                    tools like Photoshop and Illustrator, paired with his keen
                    artistic eye, ensures high-quality results for every
                    project. Savan excels at creating visually stunning and
                    effective designs that resonate with clients' needs and
                    elevate their brand presence.
                  </p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                  <p className="cs-m0">
                    Savan's creativity and professionalism guarantee that every
                    project at Dapplic is handled with the highest level of care
                    and expertise. He is committed to staying ahead of design
                    trends and continuously refining his skills to provide
                    innovative solutions. Savan's approach to design is both
                    meticulous and inspired, making him an invaluable asset to
                    the Dapplic team and a trusted partner for clients seeking
                    exceptional design work.
                  </p>
                  <Div className="cs-height_45 cs-height_lg_30" />
                 <SocialWidget /> 
                </Div>
              </Div>
              <Div className="col-xl-5 col-lg-6">
                <Div className="cs-radius_15 cs-shine_hover_1">
                  <img
                    src="/images/member_details_1.jpeg"
                    alt="Member"
                    className="w-100"
                  />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
