import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Button from "../../components/Button";
import Cta from "../../components/Cta";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";

export default function NewsClick() {
  const params = useParams();
  pageTitle("News Click");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="News Click Gujarat"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={"News Click Gujarat"}
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <img
          src="/images/NewsClick/Main_Page.jpg"
          alt="Details"
          className="cs-radius_15 w-100"
        />
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title="News Click Gujarat" subtitle="Creative">
              <Spacing lg="40" md="20" />
              <p>
                Dapplic proudly partnered with News Click Gujarat, a local news
                YouTube channel serving the community of Khambhat, Gujarat. Our
                team successfully delivered a comprehensive branding package,
                including a distinctive logo, detailed brand guidelines, and an
                engaging logo promo. The new visual identity captures the
                essence of the channel, providing a cohesive and professional
                look that resonates with its audience.
              </p>
              <Spacing lg="10" md="10" />
              <p>
                The project's success was marked by the personal collaboration
                with the channel's founder, who was thoroughly impressed by the
                unexpected and exceptional results we delivered. The logo
                embodies the channel's commitment to delivering timely and
                reliable local news, while the brand guidelines ensure
                consistency across all platforms. The logo promo adds a dynamic
                element, enhancing viewer engagement and establishing a strong
                brand presence in the digital space.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                <p className="cs-m0">Logo Design, Brand Design</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Location:
                </h3>
                <p className="cs-m0">India</p>
                <Spacing lg="30" md="30" />
              </Div>
              {/* <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                <p className="cs-m0">Adobe Illustrator</p>
                <Spacing lg="30" md="30" />
              </Div> */}
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Dated:
                </h3>
                <p className="cs-m0">22-May-2024</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Client:
                </h3>
                <p className="cs-m0">Mahesh Vaghela</p>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        {/* <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/portfolio/portfolio-details"
              btnText="Prev Project"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button
              btnLink="/portfolio/portfolio-details"
              btnText="Next Project"
            />
          </Div>
        </Div> */}
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info.dapplic@gmail.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
