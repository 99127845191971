import { Route, Routes } from "react-router-dom";
import AboutPage from "./Pages/AboutPage";
import BlogPage from "./Pages/BlogPage";
import BlogDetailsPage from "./Pages/BlogDetailsPage";
import ContactPage from "./Pages/ContactPage";
import ErrorPage from "./Pages/ErrorPage";
import Home from "./Pages/Home";
import PortfolioDetailsPage from "./Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./Pages/ServiceDetailsPage";
import ServicesPage from "./Pages/ServicesPage";
import GraphicDesign from "./Pages/ServiceDetails/GraphicDesign";
import MobileAppDevelopment from "./Pages/ServiceDetails/MobileAppDevelopment";
import UIUXDesgin from "./Pages/ServiceDetails/UIUXDesgin";
import WebsiteDevelopment from "./Pages/ServiceDetails/WebsiteDevelopment";
import PortfolioPage from "./Pages/PortfolioPage";
import NewsClick from "./Pages/ProjectDetails/NewsClick";
import Layout from "./components/Layout";
import FaqPage from "./Pages/FaqPage";
import RoxHightech from "./Pages/ProjectDetails/Roxhightech";
import Electro from "./Pages/ProjectDetails/Electro";
import GauAmrutam from "./Pages/ProjectDetails/GauAmrutam";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="service/graphic-design" element={<GraphicDesign />} />
          <Route path="service/uiux-design" element={<UIUXDesgin />} />
          <Route
            path="service/website-development"
            element={<WebsiteDevelopment />}
          />
          <Route
            path="service/mobile-app-development"
            element={<MobileAppDevelopment />}
          />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/news-click" element={<NewsClick />} />
          <Route path="portfolio/roxhightech" element={<RoxHightech />} />
          <Route path="portfolio/electro" element={<Electro />} />
          <Route path="portfolio/gau-amrutam" element={<GauAmrutam />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="contact" element={<ContactPage />} />

          <Route path="faq" element={<FaqPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
