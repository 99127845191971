import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import { pageTitle } from "../helper";
import Div from "../components/Div";
import PageHeading from "../components/PageHeading";
import SectionHeading from "../components/SectionHeading";
import Spacing from "../components/Spacing";
import ContactInfoWidget from "../components/Widget/ContactInfoWidget";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [Message, setMessage] = useState("");
  const [projectType, setProjectType] = useState("");
  const [showSuccess, setshowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  pageTitle("Contact Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const templateParams = {
        Name: Name,
        Email: Email,
        projectType: projectType,
        mobile: mobile,
        Message: Message,
      };
      console.log("PARAMS ::: ", templateParams);
      if (Name.length > 0 || Email.length > 0 || projectType.length > 0) {
        emailjs
          .send("service_t7rxopj", "template_7hm8yf8", templateParams, {
            publicKey: "f1gP7swxiMCVqvvvz",
          })
          .then(
            (response) => {
              setName("");
              setEmail("");
              setMobile("");
              setMessage("");
              setProjectType("");
              setshowSuccess(true);
              console.log("SUCCESS!", response.status, response.text);
            },
            (err) => {
              setShowError(true);
              console.log("FAILED...", err);
            }
          );
      } else {
        setShowError(true);
      }
    },
    [Name, Email, projectType, mobile, Message]
  );

  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Let's Make Something <br/>Great Together"
              subtitle="Say Hello"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  value={Name}
                  onFocus={() => {
                    setShowError(false);
                    setshowSuccess(false);
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setName(e.target.value);
                  }}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  value={Email}
                  onFocus={() => {
                    setShowError(false);
                    setshowSuccess(false);
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  onFocus={() => {
                    setShowError(false);
                    setshowSuccess(false);
                  }}
                  value={projectType}
                  onChange={(e) => {
                    e.preventDefault();
                    setProjectType(e.target.value);
                  }}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile</label>
                <input
                  type="text"
                  className="cs-form_field"
                  onFocus={() => {
                    setShowError(false);
                    setshowSuccess(false);
                  }}
                  value={mobile}
                  onChange={(e) => {
                    e.preventDefault();
                    setMobile(e.target.value);
                  }}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  onFocus={() => {
                    setShowError(false);
                    setshowSuccess(false);
                  }}
                  value={Message}
                  onChange={(e) => {
                    e.preventDefault();
                    setMessage(e.target.value);
                  }}
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button
                  className="cs-btn cs-style1"
                  onClick={(e) => handleSubmit(e)}
                >
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
              {showSuccess && (
                <span className="cs-success_color">
                  Message sent successfully!
                </span>
              )}
              {showError && (
                <span className="cs-error_color">
                  Please fill the mandatory details or contact at
                  info.dapplic@gmail.com
                </span>
              )}
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />

      <Spacing lg="50" md="40" />
    </>
  );
}
