import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Cta from "../../components/Cta";
import IconBox from "../../components/IconBox";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import TestimonialSlider from "../../components/Slider/TestimonialSlider";
import Spacing from "../../components/Spacing";

export default function MobileAppDevelopment() {
  pageTitle("Mobile App Development");
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Mobile App Development"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={"Mobile App Development"}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="DEVELOPMENT WORKING PROCESS"
          subtitle="MOBILE APP DEVELOPMENT"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.svg"
              title="Conceptualization"
              subtitle="We start by conceptualizing your app idea and defining its core features. This helps us create a clear vision for the project."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.svg"
              title="Designing"
              subtitle="Our design team creates intuitive and attractive app interfaces that enhance user engagement. We focus on both aesthetics and usability."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.svg"
              title="Development"
              subtitle="Finally, our developers build the app using robust frameworks and coding standards. We ensure the app performs flawlessly across all devices."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              Below our most development related services
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="iOS App Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Android App Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Cross-Platform App Development"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="UI/UX Design for Apps"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="App Prototyping"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="App Maintenance"
                  variant="cs-type2"
                />
                <Spacing lg="0" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  // btnLink="/service/service-details"
                  btnText="App Store Optimization"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="App Testing"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Custom App Solutions"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  // btnLink="/service/service-details"
                  btnText="Integration Services"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
