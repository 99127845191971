import React, { useEffect } from "react";
import Card from "../components/Card";
import FunFact from "../components/FunFact";
import Hero from "../components/Hero";
import Div from "../components/Div";
import SectionHeading from "../components/SectionHeading";
import Spacing from "../components/Spacing";
import Cta from "../components/Cta";
import LogoList from "../components/LogoList";
import MovingText from "../components/MovingText";
import PortfolioSlider from "../components/Slider/PortfolioSlider";
import PostSlider from "../components/Slider/PostSlider";
import TestimonialSlider from "../components/Slider/TestimonialSlider";
import VideoModal from "../components/VideoModal";
import { pageTitle } from "../helper";
import IconBox from "../components/IconBox";
// Hero Social Links
const heroSocialLinks = [
  {
    name: "Behance",
    links: "/",
  },
  {
    name: "Dribbble",
    links: "/",
  },
];

// FunFact Data
const funfaceData = [
  {
    // title: "Projects Completed",
    // factNumber: "5",
  },
  {
    title: "Global Happy Clients",
    factNumber: "4",
  },
  {
    //   title: "Team Members",
    //   factNumber: "3",
  },
  {
    title: "Projects Completed",
    factNumber: "5",
  },
];
const portfolioData = [
  {
    title: "News Click Gujarat",
    subtitle: "See Details",
    href: "/portfolio/news-click",
    src: "/images/NewsClick/Website_Structure-02.jpg",
  },
  {
    title: "Gau Amrutam",
    subtitle: "See Details",
    href: "/portfolio/gau-amrutam",
    src: "/images/portfolio_2.jpeg",
  },
  {
    title: "Electro",
    subtitle: "See Details",
    href: "/portfolio/electro",
    src: "/images/portfolio_0.jpg",
  },
  {
    title: "RoxHightech",
    subtitle: "See Details",
    href: "/portfolio/roxhightech",
    src: "/images/portfolio_3.jpeg",
  },
];

export default function Home() {
  pageTitle("Home");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="From thinking <br/>to reality"
        subtitle="We bring your vision to life with creativity and innovation, offering exceptional design solutions tailored to your needs."
        btnText="Let's Collaborate"
        btnLink="/contact"
        scrollDownId="#service"
        // socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
        bannerHighlightText="/images/highlight_D.svg"
        spiningCircleUrl="/images/highlight_texts.svg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="From crafting unique brands to delivering top-notch design projects, we're just getting started."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="UI/UX Design ~ Brand Design ~ Logo Design ~ Packaging Design ~ Motion Graphics ~ Graphic Design ~ " />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      <Div className="row">
        <Div className="col-lg-4">
          <IconBox
            icon="/images/icons/service_icon_1.svg"
            title="Sketching"
            subtitle="In the initial stage, we brainstorm and create rough sketches to bring your ideas to life. This helps us visualize the concept and make necessary adjustments early on."
          />
          <Spacing lg="30" md="30" />
        </Div>
        <Div className="col-lg-4">
          <IconBox
            icon="/images/icons/service_icon_2.svg"
            title="Wireframing"
            subtitle="Next, we develop detailed wireframes that serve as a blueprint for the final design. This step ensures that all elements are properly arranged and functional."
          />
          <Spacing lg="30" md="30" />
        </Div>
        <Div className="col-lg-4">
          <IconBox
            icon="/images/icons/service_icon_3.svg"
            title="Prototyping"
            subtitle="Finally, we create high-fidelity prototypes that showcase the complete look and feel of your project. This allows us to test and refine the design before the final implementation."
          />
          <Spacing lg="30" md="30" />
        </Div>
      </Div>

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      {/* <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="SERVICES WE OFFER"
                subtitle="What We Can Do"
                // btnText="See All Services"
                // btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row ">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Logo Design"
                    description={`Crafting unique and memorable logos that reflect your brand's identity.`}
                    link="/service/website-Development"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Brand Design"
                    description={`Developing cohesive brand identities that resonate with your target audience.`}
                    link="/service/website-Development"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Packaging design"
                    description={`Designing attractive and functional packaging that enhances product appeal.`}
                    link="/service/uiux-design"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Spacing lg="30" md="30" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Graphic design"
                    description={`Creating visually striking designs for various media and purposes.`}
                    link="/service/uiux-design"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Motion design"
                    description={`Bringing stories to life with dynamic and engaging motion graphics.`}
                    link="/service/uiux-design"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="UI/UX Desgin"
                    description={`Designing intuitive and engaging user interfaces for web and mobile applications.`}
                    link="/service/graphic-design"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Div>
        <Div className="container">
          <SectionHeading
            title="SERVICES WE OFFER"
            subtitle="What We Can Do"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <Div className="col-xl-8">
          <Div className="row ">
            <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="Logo Design"
                description={`Crafting unique and memorable logos that reflect your brand's identity.`}
                link="/service/website-Development"
                src="/images/service_4.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="Brand Design"
                description={`Developing cohesive brand identities that resonate with your target audience.`}
                link="/service/website-Development"
                src="/images/service_4.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="Packaging design"
                description={`Designing attractive and functional packaging that enhances product appeal.`}
                link="/service/uiux-design"
                src="/images/service_1.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="Graphic design"
                description={`Creating visually striking designs for various media and purposes.`}
                link="/service/uiux-design"
                src="/images/service_1.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="Motion design"
                description={`Bringing stories to life with dynamic and engaging motion graphics.`}
                link="/service/uiux-design"
                src="/images/service_1.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Card
                title="UI/UX Desgin"
                description={`Designing intuitive and engaging user interfaces for web and mobile applications.`}
                link="/service/graphic-design"
                src="/images/service_2.jpeg"
                alt="Service"
              />
              <Spacing lg="0" md="30" />
            </Div>
            <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Discover Our Latest Projects"
            subtitle="Showcase of Excellence"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider data={portfolioData} />
      </Div>
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      {/* <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          We see change as a spark for creativity, inspiring us to craft
          impactful digital solutions.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div> */}
      {/* End Video Block Section */}

      <Spacing lg="150" md="80" />

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      <Spacing lg="150" md="80" />

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      {/* End LogoList Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      <Spacing lg="150" md="80" />

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s connect and create<br /> <i>something </i>amazing together!"
          btnText="Begin Your Journey"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
